/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        
        // Hamburger
				var $hamburger = $(".hamburger");
				var $nav = $(".china-red-menu .menu li a");
				
				$hamburger.on("click", function(e) {
					$hamburger.toggleClass("is-active");
					$(".china-red-menu").toggleClass("china-red-menu-active");
					$("body").toggleClass("modal-open");
				});
			  
			  $nav.on("click", function(e) {
				  $hamburger.removeClass("is-active");
				  $(".china-red-menu").removeClass("china-red-menu-active");
					$("body").removeClass("modal-open");
			  });
        
        // Header
		    $(window).on("scroll", function() {
		        if($(window).scrollTop() > 0) {
		            $(".china-red-header").addClass("bg-dark");
		        } else {
		            //remove the background property so it comes transparent again (defined in your css)
		           $(".china-red-header").removeClass("bg-dark");
		        }
		    }); 
        
        // HVR
        $("p a").addClass("hvr-underline-from-left");
        
				// Lightbox2
				lightbox.option({
					'disableScrolling': true,
					'maxWidth': 1200,
					'positionFromTop': 100,
					'showImageNumberLabel': false
    		});
			  
			  // Slick
				$('.china-red-gallery').slick({
					autoplay: true,
					arrows: false,
				  infinite: true,
				  slidesToShow: 5,
				  slidesToScroll: 1,
					responsive: [
						{
							breakpoint: 992,
							settings: {
								slidesToShow: 3
							}
						}
					]
				});
				
        // SmoothScroll
				var scroll = new SmoothScroll('a[href*="#"]', {
					offset: 100,
					speed: 1000,
					updateURL: false
				});
				
        // AOS
        AOS.init({
	        once: false
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
